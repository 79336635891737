<template>
    <div style="display:none;"></div>
</template>
<script>
export default {
    props: {
        showTime: {
            type: String,
            default: '未开始'
        },
        timespan: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            time: '',
            timer: null,
            count: 0
        };
    },
    watch: {
        timespan(newValue) {
            this.count = Math.floor((newValue - Number(new Date())) / 1000);
            if (this.count > 0) {
                clearInterval(this.timer);
                this.timer = null;
                this.countDown();
            } else {
                this.$emit('update:showTime', '结束了');
            }
        }
    },
    created() {
        this.count = Math.floor((this.timespan - Number(new Date())) / 1000);
        if (this.count > 0) {
            this.countDown();
        } else {
            this.$emit('update:showTime', '结束了');
        }
    },
    methods: {
        countDown() {
            this.timer = setInterval(() => {
                if (this.count <= 0) {
                    clearInterval(this.timer);
                    this.timer = null;
                    this.$emit('finish');
                } else {
                    this.count -= 1;
                    this.$emit('update:showTime', this.secondToString(this.count));
                }
            }, 1000);
        },
        secondToString(value) {
            const hours = Math.floor(value / 3600);
            const min = Math.floor((value - 3600 * hours) / 60);
            const second = value - 3600 * hours - 60 * min;
            return `${hours > 0 ? this.toDoubleString(hours) + ':' : ''}${this.toDoubleString(min)}:${this.toDoubleString(second)}`;
        },
        toDoubleString(value) {
            if (value < 10 && value >= 0) {
                return `0${value}`;
            }
            return value;
        }
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }
};
</script>
