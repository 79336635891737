<template>
    <div>
        <div class="choiceCar-tit">
            <font
                color="#ff470f"
                @click="goBack"
            >返回上一页</font>
        </div>
        <div class="content">
            <div class="modelTest-con">
                <div class="modelTest-info ">
                    <div class="info-tit">考生信息</div>
                    <div class="info-con">
                        <div class="info-con-img">
                            <img :src="headImageUrl || require('../../images/avatar.png')" />
                        </div>
                        <p>姓名：{{userName}}</p>
                        <p>考试类型：{{carStr}}</p>
                        <p>考试科目：{{kemu}}</p>
                    </div>
                    <div class="info-time">
                        <p>剩余时间</p>
                        <p class="time-txt">{{showTime}}</p>
                        <count-down
                            :showTime.sync="showTime"
                            :timespan="timeSpan"
                            @finish="handleFinish"
                        ></count-down>
                    </div>
                </div>
                <div
                    class="modelTest-topic "
                    v-show="questions.length>0"
                >
                    <div class="info-tit">题目信息</div>
                    <div class="topic-con flex">
                        <div class="topic-con-icon fl">
                            <span>{{currIndex+1+'.'+ model.QuestionTypeStr}}</span>
                        </div>
                        <div class="topic-con-test fl">
                            <div class="test-tit">{{model.Title}}</div>
                            <div
                                class="test-img"
                                v-show="model.SmallImgUrl && !model.SmallImgUrl.includes('default-image')"
                            >
                                <img
                                    :src="model.SmallImgUrl"
                                    @click="showPopup(model.ImgUrl)"
                                />
                            </div>
                            <!--1.单选题 -->
                            <div
                                class="test-sle"
                                v-show="model.QuestionType==1"
                            >
                                <p
                                    v-for="(item,index) in model.AnswerList"
                                    :key="index"
                                    :class="singleOption==item.Option?'sle-lin':''"
                                    @click="sleTest(index)"
                                >{{item.Option+'.'+item.Content}}</p>
                            </div>
                            <!--2.多选题 -->
                            <div
                                class="test-sle"
                                v-show="model.QuestionType==2"
                            >
                                <p
                                    v-for="(item,index) in model.AnswerList"
                                    :key="index"
                                    :class="multiOptions.indexOf(item.Option)>-1?'sle-lin':''"
                                    @click="sleTest(index)"
                                >{{item.Option+'.'+item.Content}}</p>
                            </div>
                            <!--3.判断题 -->
                            <div
                                class="test-sle"
                                v-show="model.QuestionType==3"
                            >
                                <p
                                    :class="judge=='正确'?'sle-lin':''"
                                    @click="sleTest('正确')"
                                >正确</p>
                                <p
                                    :class="judge=='错误'?'sle-lin':''"
                                    @click="sleTest('错误')"
                                >错误</p>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="topic-hint">提示：判断题请判断对错！</div> -->
                    <div class="topic-btn">
                        <div
                            class="topic-btn-on"
                            style="cursor:pointer;"
                            @click="prev"
                        >&lt; 上一题</div>
                        <div
                            class="topic-btn-next"
                            style="cursor:pointer;"
                            @click="next"
                        >下一题&gt;</div>
                        <div
                            class="
                            topic-btn-cm"
                            @click="submitTest()"
                            style="cursor:pointer;"
                        >
                            <div class="btn-cm-bg">交卷</div>
                        </div>
                    </div>
                </div>
                <div class="modelTest-answer ">
                    <ul>
                        <li
                            v-for="(item,index) in questions"
                            :key="index"
                            @click="jump(index)"
                            :class="currIndex===index?'on':''"
                        >
                            <span>{{index+1}}</span>
                            <font color="#37c648">{{item.answer}}</font>
                            <!-- <font color="#37c648">b</font>
							<font color="#37c648">c</font>
							<font color="#37c648">c</font> -->
                        </li>
                    </ul>
                </div>
            </div>
            <!--弹窗-->
            <div
                class="popup"
                v-show="subTest"
            >
                <div class="active-popup animated fadeIn">
                    <div class="popup-modelTest-tit">考试确认窗口</div>
                    <div class="popup-modelTest-con">
                        <p>操作提示：</p>
                        <p>1、点击【确认交卷】，将提交考试成绩，结束考试</p>
                        <p>2、点击【继续答题】，将继续答题，倒计时结束将无法答题</p>
                    </div>
                    <div class="vip-popup-btn flex flex-jc">
                        <div
                            class="popup-btn01"
                            @click="go()"
                        >继续答题</div>
                        <div
                            class="popup-btn02"
                            @click="sub()"
                        >现在交卷</div>
                    </div>
                </div>
            </div>
            <!-- 离开 -->
            <div
                class="popup"
                v-show="leaveNoticeVisible"
            >
                <div class="active-popup animated fadeIn leave-height">
                    <div class="popup-modelTest-tit">操作提示</div>
                    <div class="popup-modelTest-con">
                        <p>当前正在考试，是否要离开？</p>
                    </div>
                    <div class="vip-popup-btn flex flex-jc">
                        <div
                            class="popup-btn01"
                            @click="notLeave"
                        >取消</div>
                        <div
                            class="popup-btn02"
                            @click="sureLeave"
                        >确定</div>
                    </div>
                </div>
            </div>

            <!-- 图片放大 -->
            <template>
                <div
                    class="popup"
                    v-show="isPopup"
                >
                    <div
                        class="popup_back"
                        @click="hidePopup()"
                    ></div>
                    <div class="exercises_popup animated fadeIn">
                        <img :src="zoomImgUrl || '../../images/wide_seat.png'" />
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import '../../styles/public.scss';
@import 'modelTest.scss';
</style>
<script>
// import Cookies from 'js-cookie';
import countDown from '@/components/count-down.vue';
import { mapState } from 'vuex';

export default {
    name: '',
    components: {
        'count-down': countDown
    },
    computed: {
        ...mapState(['car'])
    },
    data() {
        return {
            leaveNoticeVisible: false,
            willLeave: false,
            isActive: 0,
            singleOption: '', // 单选题答案
            multiOptions: [], // 多选题答案
            judge: '', // 判断题答案
            subTest: false,
            showTime: '',
            ktype: 1,
            carId: 0,
            timeSpan: Number(new Date()),
            questions: [],
            currIndex: 1,
            model: {
                QuestionTypeStr: '',
                Title: '',
                ImgUrl: '',
                AnswerIds: '',
                QuestionType: 0, // 单选题=1 多选题=2 判断题=3,
                AnswerList: [],
                selected: false
            },
            kemu: '科目',
            carStr: '',
            userName: '',
            headImageUrl: '',
            testing: false,
            score: 0,
            submitExam: false,
            isPopup: false,
            zoomImgUrl: ''
        };
    },
    methods: {
        // 显示
        showPopup(url) {
            this.zoomImgUrl = url;
            this.isPopup = true;
        },
        hidePopup() {
            this.isPopup = false;
        },
        // 取消离开
        notLeave() {
            this.willLeave = false;
            this.leaveNoticeVisible = false;
        },
        // 确定离开
        sureLeave() {
            this.willLeave = true;
            // const user = this.$util.getUser();
            // if (user) {
            //     const key = `leaveTime-${this.ktype}-${this.carId}-${user.Id}`;
            //     console.log('离开时间', Number(new Date()));
            //     Cookies.set(key, Number(new Date()));
            // }
            this.leaveNoticeVisible = false;
        },
        handleFinish() {
            this.testing = true;
            this.submitTest();
        },
        sleTest(index) {
            if (this.model.selected) {
                return;
            }
            switch (this.model.QuestionType) {
                case 1:// 单选题
                    var curr1 = this.model.AnswerList[index];
                    // console.log('curr11:', curr1);
                    this.questions[this.currIndex].answer = curr1.Option;
                    this.singleOption = curr1.Option;
                    break;
                case 2:// 多选题
                    var curr2 = this.model.AnswerList[index];
                    var cq = this.questions[this.currIndex];
                    var ix = cq.arr.indexOf(curr2.Option);
                    if (ix > -1) {
                        cq.arr.splice(ix, 1);
                    } else {
                        cq.arr.push(curr2.Option);
                    }
                    this.multiOptions = cq.arr;
                    cq.answer = cq.arr.length > 0 ? cq.arr.join('') : '';
                    break;
                case 3:// 判断题
                    this.questions[this.currIndex].answer = index;
                    this.judge = index;
                    //  console.log('index:', index);
                    break;
            }
        },
        /*
        * 交卷
        * */
        submitTest() {
            this.subTest = true;
        },
        /*
        * 继续答题
        * */
        go() {
            if (this.testing) {
                return;
            }
            this.subTest = !this.subTest;
        },
        getData(type, carId, categoryId) {
            this.$api.getProblemTestList({ type, carId, categoryId }).then(res => {
                if (res.data.length === 0) {
                    this.$message({
                        message: '当前题库暂无考题',
                        type: 'warning'
                    });
                    setTimeout(() => {
                        this.$router.back(-1);
                    }, 1000);
                } else {
                    this.questions = res.data.map(item => {
                        return {
                            pid: item.ProblemId,
                            answer: '', // 单选/判断题答案
                            arr: [], // 多选题答案
                            model: {}
                        };
                    });
                    if (this.questions.length > 0) {
                        this.currIndex = 0;
                        this.detail(this.questions[0].pid);
                    }
                }
            });
        },
        carInfo(id) {
            // this.$api.getCarInfo(id).then(res => {
            //     this.carStr = res.data.Name;
            // });
            if (this.car.car) {
                const currentCar = this.car.car.find(a => a.Id === id);
                if (currentCar) {
                    this.carStr = currentCar.Name;
                }
            }
        },
        detail(id) {
            this.singleOption = '';
            this.multiOptions = [];
            this.judge = '';
            // this.questions[this.currIndex].

            this.$api.getProblemInfo(id).then(res => {
                this.model = res.data;
                var cc = this.questions.find(a => a.pid === this.model.Id);
                if (cc) {
                    cc.model = this.model;
                }
                this.model.selected = !!this.questions[this.currIndex].answer;

                switch (this.model.QuestionType) {
                    case 1:// 单选题
                        this.singleOption = this.questions[this.currIndex].answer;
                        break;
                    case 2:// 多选题
                        this.multiOptions = this.questions[this.currIndex].arr;
                        break;
                    case 3:// 判断题
                        this.judge = this.questions[this.currIndex].answer;
                        break;
                }
            });
        },
        // 上一题
        prev() {
            if (this.currIndex > 0) {
                this.saveFinishedQuestion();
                this.currIndex -= 1;
                this.detail(this.questions[this.currIndex].pid);
            } else {
                this.$message({
                    message: '已经是第一题了！',
                    type: 'warning'
                });
            }
        },
        // 下一题
        next() {
            if (this.currIndex < this.questions.length - 1) {
                this.saveFinishedQuestion();
                this.currIndex += 1;
                this.detail(this.questions[this.currIndex].pid);
            } else {
                this.$message({
                    message: '已经是最后一题了！',
                    type: 'warning'
                });
            }
        },
        // 保存题
        saveFinishedQuestion() {
            let isError = false;
            let isTest = false;
            let answerIds = '';
            if (this.model.QuestionType === 2) {
                const answer = this.questions[this.currIndex].arr;
                isError = answer.some(a => this.model.AnswerIds.search(a) < 0);
                isTest = answer.length > 0;
                answerIds = answer.join(',');
            } else {
                const answer = this.questions[this.currIndex].answer;
                isError = answer !== this.model.AnswerIds;
                isTest = !!answer;
                answerIds = answer;
            }
            if (isTest) {
                this.$api.saveWrongQuestion({ problemId: this.model.Id, categoryId: Number(this.$route.query.firstCateId), isError, answerIds });
            }
        },
        // 跳题
        jump(index) {
            if (this.currIndex !== index) {
                this.saveFinishedQuestion();
                this.currIndex = index;
                this.detail(this.questions[this.currIndex].pid);
            }
        },
        // 交卷
        sub() {
            // var et = Cookies.get(`examTime-${this.ktype}-${this.carId}-${this.userId}`);
            var et = this.timeSpan;
            // if (et) {
            // Cookies.remove(`examTime-${this.ktype}-${this.carId}-${this.userId}`);
            // Cookies.remove(`leaveTime-${this.ktype}-${this.carId}-${this.userId}`);
            var jt = Number(new Date());
            var cha = 2700000 - (et - jt);

            var seconds = parseInt(Math.abs(cha) / 1000);
            // const hours = Math.floor(seconds / 3600);
            const min = Math.floor(seconds / 60);
            const lseconds = seconds - min * 60;
            var useTime = `${min}分${lseconds}秒`;// 用时
            // console.log('useTime:', useTime);
            this.check(useTime);

            // router- submitExam
            this.subTest = false;
            // }
        },
        // 阅卷
        check(time) {
            var cr = this.ktype === 1 ? 1 : 2;// 分数 科1：1分，科4：2分
            for (let index = 0; index < this.questions.length; index++) {
                var obj = this.questions[index];
                var right = false;
                try {
                    if (obj) {
                        // console.log('obj:', obj);
                        var pm = obj.model;
                        // console.log('pm:', pm);
                        if (pm) {
                            switch (pm.QuestionType) {
                                case 1:// 单选题
                                case 3:// 判断题
                                    var an = pm.AnswerIds.replace(',', '');
                                    if (an === obj.answer) {
                                        right = true;
                                    } else {

                                    }
                                    break;
                                case 2:// 多选题
                                    var ans = pm.AnswerIds.split(',').filter(a => a);
                                    if (ans.length > 0 && ans.length === obj.arr.length) {
                                        right = true;
                                    }
                                    break;
                            }
                        }
                    }
                } catch (error) {
                    right = false;
                }

                if (right) {
                    this.score += cr;
                } else {

                }
            }
            // console.log('score:', this.score);
            this.submitExam = true;
            this.$router.push({ name: 'submit_exam', params: { min: time, score: this.score }, query: { type: this.$route.query.type } });
        },
        goBack() {
            this.$router.push('/choiceCar');
        }
    },
    created() {
        var carId = Number(this.$route.query.carId);
        this.carId = carId;
        this.carInfo(carId);

        var type = Number(this.$route.query.type);
        this.ktype = type;
        const categoryId = Number(this.$route.query.firstCateId);
        this.getData(type, carId, categoryId);
        switch (type) {
            case 1:
                this.kemu = '科目一';
                break;
            case 4:
                this.kemu = '科目四';
                break;
        };

        const obj = this.$util.getUser();
        if (obj) {
            this.userId = obj.Id;
            this.userName = obj.Name;
            this.headImageUrl = obj.HeadImageUrl;
            // check timestamp
            // var examTime = Cookies.get(`examTime-${type}-${carId}-${obj.Id}`);
            // if (examTime) {
            //     const ts = Number(examTime);
            //     const leaveKey = `leaveTime-${type}-${carId}-${obj.Id}`;
            //     const leaveTime = Cookies.get(leaveKey);
            //     if (leaveTime && Number(leaveTime) < ts) {
            //         const lt = Number(new Date()) - Number(leaveTime);
            //         console.log('lt', lt);
            //         this.timeSpan = ts + lt;
            //         this.subTest = true;
            //         Cookies.set(`examTime-${type}-${carId}-${obj.Id}`, ts + lt);
            //         Cookies.remove(`leaveTime-${type}-${carId}-${obj.Id}`);
            //     } else {
            //         if (ts < Number(new Date())) {
            //             this.timeSpan = Number(new Date()) + 2700000;
            //             Cookies.set(`examTime-${type}-${carId}-${obj.Id}`, this.timeSpan);
            //             Cookies.remove(`leaveTime-${type}-${carId}-${obj.Id}`);
            //         } else {
            //             this.timeSpan = ts;
            //             this.subTest = true;
            //         }
            //     }
            // } else {
            //     this.timeSpan = Number(new Date()) + 2700000;
            //     Cookies.set(`examTime-${type}-${carId}-${obj.Id}`, this.timeSpan);
            //     Cookies.remove(`leaveTime-${type}-${carId}-${obj.Id}`);
            // }
            this.timeSpan = Number(new Date()) + 2700000;
        }
    },
    // beforeRouteEnter(to, from, next) {
    //     console.log('to', to);
    //     next(vm => {
    //         const user = vm.$util.getUser();
    //         if (user) {
    //             const key = `examTime-${to.query.type}-${to.query.carId}-${user.Id}`;
    //             const examTime = Cookies.get(key);
    //             if (examTime) {
    //                 const leaveKey = `leaveTime-${to.query.type}-${to.query.carId}-${user.Id}`;
    //                 const leaveTime = Cookies.get(leaveKey);
    //                 if (leaveTime && Number(leaveTime) < Number(examTime)) {
    //                     const lt = Number(new Date()) - Number(leaveTime);
    //                     console.log('新的时间', Number(examTime) + lt);
    //                     Cookies.set(key, Number(examTime) + lt);
    //                 }
    //             }
    //         }
    //     });
    // },
    beforeRouteLeave(to, from, next) {
        if (this.submitExam || this.questions.length === 0) {
            next();
        } else {
            this.willLeave = false;
            this.leaveNoticeVisible = true;
            const interval = setInterval(() => {
                if (!this.leaveNoticeVisible) {
                    clearInterval(interval);
                    if (this.willLeave) {
                        next();
                    } else {
                        next(false);
                    }
                }
            }, 500);
        }
    }
};
</script>
